<template>
  <van-overlay :show="boo" @click.prevent="boo = false" lock-scroll>
    <div class="wrapper" @click.stop>
      <div
        class="tips"
        :style="
          obj.type == 'random' || obj.type == 'other' ? 'height:150px' : ''
        "
      >
        <span>签到成功！</span>

        <p v-if="obj.type == 'random'">
          恭喜您随机获得一份惊喜<i>已放进卡包</i>
        </p>

        <template v-if="obj.type == 'voucher'">
          <p>
            恭喜您获得<i>{{ obj.vouchertemplate_title }}</i>
          </p>

          <img src="@image/points/dialog_coupon.png" class="prize_icon" />
        </template>

        <template v-if="obj.type == 'goods'">
          <p>
            恭喜您获得<i>{{ obj.goods.goods_name }}</i>
          </p>

          <img :src="obj.goods.goodsimage_full_url" class="prize_icon" />
        </template>

        <template v-if="obj.type == 'other'">
          <p>
            恭喜您获得<i>{{ obj.note }}</i>
          </p>
        </template>

        <template v-if="obj.type == 'lottery'">
          <p>
            恭喜您获得<i>{{ obj.num }}次抽奖</i>
          </p>

          <img src="@image/points/dialog_prize.png" class="prize_icon" />
        </template>

        <template v-if="obj.type == 'choose'">
          <p>请任意选择以下一个奖励</p>
          <div class="chooese">
            <van-radio-group v-model="radio" @change="chooeseRadio">
              <van-radio
                :name="ii + 1"
                checked-color="#FF4848"
                v-for="(item, ii) in obj.list"
                :key="ii"
              >
                <template>
                  {{
                    (item.goods && item.goods.goods_name) ||
                      (item.voucher && item.voucher.vouchertemplate_title) ||
                      (item.lottery && item.lottery.title) ||
                      item.name ||
                      item.other
                  }}
                </template>
              </van-radio>
            </van-radio-group>
          </div>
        </template>

        <div class="btn" @click="nextStep(obj)">
          <template v-if="obj.type == 'voucher' || obj.type == 'goods'">
            <span>立即收下</span>
          </template>
          <template v-if="obj.type == 'lottery'">
            <span>去抽奖</span>
          </template>

          <template v-if="obj.type == 'other' || obj.type == 'random'">
            <span>确定</span>
          </template>

          <template @click="chooesePrize" v-if="obj.type == 'choose'">
            <span>确定</span>
          </template>
        </div>
        <div
          class="subtitle"
          v-if="obj.type == 'voucher' || obj.type == 'goods'"
        >
          礼品已发放到卡包中
        </div>
        <svg
          @click.prevent="boo = false"
          class="tips_error"
          width="63"
          height="63"
          viewBox="0 0 63 63"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="31.5"
            cy="31.5"
            r="30.5"
            stroke="white"
            stroke-width="2"
          />
          <path
            d="M18 18L44.8701 44.8701"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
          />
          <path
            d="M45 18L18.1299 44.8701"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { Overlay, RadioGroup, Radio } from "vant";
import { get, post } from "@get/http";
export default {
  components: {
    vanOverlay: Overlay,
    vanRadioGroup: RadioGroup,
    vanRadio: Radio,
  },
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    chooeseObj: {},
    obj: {},
  },
  data() {
    return {
      boo: false,
      radio: "0",
    };
  },
  methods: {
    nextStep(obj) {
      this.boo = false;
      if (obj.type == "lottery") {
        //跳转抽奖
        if (obj.link && obj.link.length != 0) {
          window.location.href = obj.link;
        } else {
          this.$router.push({
            name: "LuckDraw",
            query: { id: obj.lottery.id },
          });
        }
      }

      if (
        obj.type == "goods" ||
        obj.type == "voucher" ||
        obj.type == "other" ||
        obj.type == "random"
      ) {
        //跳转抽奖
        if (obj.link && obj.link.length != 0) {
          window.location.href = obj.link;
        }
      }
    },
    chooeseRadio(item) {
      console.log(item);
    },
    async chooesePrize() {
      //获取配置

      let data = await post("/lw.MemberWelfare/chooseWelfare", {
        data: {
          id: this.chooeseObj.id || this.obj.id,
          index: this.radio,
        },
      });
      this.$emit("enter", "ok");
    },
  },
  created() {
    this.boo = this.show;
  },
  watch: {
    show() {
      this.boo = this.show;
    },
    boo() {
      this.$emit("update:show", this.boo);
    },
  },
};
</script>
<style lang="scss">
.van-overlay {
  z-index: 999999;
}
</style>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

.tips {
  width: rem(558);
  height: rem(628);
  background: #fff;
  border-radius: rem(16);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  > span {
    color: #222222;
    font-weight: bold;
    font-size: rem(34);
    text-align: center;
    display: block;
    margin-top: rem(40);
  }
  > p {
    text-align: center;
    color: #989898;
    font-size: rem(28);
    width: rem(500);
    margin: 0 auto;
    @include ell;
    margin-top: rem(10);
    > i {
      color: #ff4848;
    }
  }
  .prize_icon {
    display: block;
    width: rem(200);
    // width: rem(212);
    margin: rem(54) auto;
  }

  .subtitle {
    color: #989898;
    font-size: rem(24);
    margin-top: rem(24);
    text-align: center;
  }

  .chooese {
    width: rem(300);
    margin: 0 auto;

    text-align: center;
    // display: flex;
    justify-content: center;
    // margin-bottom: rem(30);
    // margin-top: rem(56);
    ::v-deep {
      .van-radio {
        margin-bottom: rem(50);
      }
      .van-radio__label {
        color: #222;
        font-weight: bold;
        font-size: rem(28);
        @include ell;
      }
    }
  }

  .btn {
    margin: rem(40) auto 0;
    background: linear-gradient(180deg, #ff6666 0%, #ff4848 105.88%);
    border-radius: rem(84/2);
    width: rem(480);
    height: rem(84);
    color: #f7f7f7;
    font-size: rem(30);
    line-height: rem(84);
    text-align: center;
  }
}
.tips_error {
  position: absolute;
  width: rem(63);
  height: rem(63);
  bottom: rem(-120);
  left: 0;
  right: 0;
  margin: 0 auto;
}
</style>
