<template>
  <div id="points">
    <div class="p_info">
      <div class="p_sub">
        <span
          >累计签到<i>{{ max_day }}天</i></span
        >
        <span>赢惊喜礼包</span>
      </div>

      <div
        class="rule"
        @click="
          $router.push({
            name: 'signinRule',
            query: {
              ...$route.query,
            },
          })
        "
      >
        规则
      </div>
      <div
        class="reload"
        @click="
          $router.push({
            name: 'signinLog',
            query: {
              signin_id: $route.query.signin_id,
            },
          })
        "
      >
        奖励记录
      </div>
    </div>

    <div class="p_sign">
      <h1 v-if="!activeStatus">已累计签到{{ signined }}天</h1>
      <div class="title">
        <h1 v-if="activeStatus">已累计签到{{ signined }}天</h1>
        <div v-if="activeStatus">
          活动<i>{{ time.day }}</i
          >天<i>{{ time.min }}</i
          >分<i>{{ time.sec }}</i
          >秒后结束
          <!-- <van-count-down :time="end_time" /> -->
        </div>
        <div v-else>
          活动将在{{ start_time | time }} ~ {{ end_time | time }}进行
        </div>
      </div>

      <div>
        <van-swipe
          class="my-swipe"
          :autoplay="0"
          :show-indicators="false"
          :loop="false"
        >
          <van-swipe-item v-for="(item, index) in dPointGoodsList" :key="index">
            <oList
              :originData="dPointGoodsList[index]"
              :signed="signined"
              :li_index="index * 7 + 1"
            ></oList>
          </van-swipe-item>
        </van-swipe>
      </div>

      <div
        v-if="activeStatus"
        class="sign-btn"
        @click="signinStatus ? '' : getSign()"
        :class="{
          'sign-btn-act': signinStatus,
        }"
      >
        {{ signinStatus ? "已签到" : "签到领取" }}
      </div>

      <div v-if="!activeStatus" class="sign-btn sign-btn-act">
        不在活动范围内
      </div>

      <!-- 
      <div
        class="sign-btn"
        @click="getSign"
        :class="{
          'sign-btn-act': signinStatus,
        }"
      >
        签到领取
      </div> -->

      <div class="get_kefu" @click="kefuBoo = true">
        咨询客服
      </div>
    </div>
    <oSigned
      :show.sync="signedShow"
      :obj="thisSigninObj"
      :chooeseObj="thisChooeseItem"
    ></oSigned>
    <moModel :moData="moData"></moModel>
    <oChat :boo.sync="kefuBoo"></oChat>
  </div>
</template>

<script>
import { Swipe, SwipeItem, CountDown } from "vant";
import moModel from "@/view/home/components/models";
import oCoins from "./components/coinsIcon.vue";
import oCoinsed from "./components/coinsIconed.vue";
import oSigned from "./components/signed.vue";
import { get, post } from "@get/http";
import oChat from "@/view/me/view/chat/popup.vue";
import { getQueryVariable } from "@get/util";
import oList from "./components/list.vue";
export default {
  components: {
    oCoins,
    oCoinsed,
    oSigned,
    oList,
    vanSwipe: Swipe,
    vanSwipeItem: SwipeItem,
    moModel,
    oChat,
    vanCountDown: CountDown,
  },
  data() {
    return {
      end_time: 0,
      start_time: 0,
      kefuBoo: false,
      signedShow: false,
      logShow: false,
      dPointGoodsList: [],
      moData: {},
      signined: 0,
      signinStatus: false,
      thisSigninObj: {},
      // thisRandomItem: null,
      thisChooeseItem: {},
      mofang_url: "",
      max_day: 0,
      activeStatus: false,
      signin_id: 0,
      time: {
        second: 0,
        day: 0,
        hr: 0,
        min: 0,
        sec: 0,
      },
    };
  },
  async created() {
    //获取配置
    this.signin_id = getQueryVariable("signin_id");
    let { content, max_day, end_time, mofang_url, start_time } = await post(
      "/lw.MemberWelfare/getSigninWelfareConfig",
      {
        data: {
          id: this.signin_id,
        },
      }
    );
    this.mofang_url = mofang_url;
    this.max_day = max_day;
    this.end_time = end_time;
    this.start_time = start_time;
    let nowTime = parseInt(new Date().getTime() / 1000);

    if (!(start_time < nowTime && end_time > nowTime)) {
      console.log("未开始或者已结束");
    } else {
      let timeres = null;
      this.activeStatus = true;
      timeres = setInterval(() => {
        let e = end_time - parseInt(new Date().getTime() / 1000);

        if (e <= 0) {
          clearInterval(timeres);
          this.activeStatus = false;
        } else {
          this.date_format(e * 1000);
        }
      }, 1000);
    }

    //获取签到信息
    let s = await post("/lw.MemberWelfare/getSigninLogByID", {
      data: {
        welfare_id: this.signin_id,
      },
    });
    if (s) {
      if (s.code) {
        this.signined = 0;
      } else {
        this.signined = s;
      }
    }

    //检查是否已经签到
    let isSignin_v2 = await post("/lw.Member/isSignin_v2", {
      data: {
        welfare_id: this.signin_id,
      },
    });
    if (isSignin_v2 == 1) {
      this.signinStatus = true;
      this.getNoneChooeseData();
    }

    let list = [];
    let _listIndex = Math.ceil(max_day / 7);
    for (let index = 0; index < _listIndex; index++) {
      list.push([]);
    }

    for (let index = 1; index <= max_day; index++) {
      let result = content.find((item) => item.day == index);
      let q = result ? result : content[0];
      if (index <= 7) {
        list[0].push(q);
      } else if (index >= 8 && index <= 14) {
        list[1].push(q);
      } else {
        list[2].push(q);
      }
    }
    this.dPointGoodsList = list;

    this.getIndexModelData();
  },
  methods: {
    date_format(micro_second) {
      // 总秒数
      var second = Math.floor(micro_second / 1000);
      // 天数
      var day = Math.floor(second / 3600 / 24);
      // 小时
      var hr = Math.floor((second / 3600) % 24);
      // 分钟
      var min = Math.floor((second / 60) % 60);
      // 秒
      var sec = Math.floor(second % 60);

      this.time = {
        day: day,
        hr: hr,
        min: min,
        sec: sec,
      };

      // return day + "天" + hr + "小时" + min + "分钟" + sec + "秒";
    },
    HTMLDecode(text) {
      var temp = document.createElement("div");
      temp.innerHTML = text;
      var output = temp.innerText || temp.textContent;
      temp = null;
      return output;
    },
    async getIndexModelData() {
      /***
       * @method getIndexModelData 获取首页模块数据
       */

      // let src = this.HTMLDecode();
      // if (src) {
      let store_id = this.getSrcVariable(this.mofang_url, "store_id");
      let id = this.getSrcVariable(this.mofang_url, "s_id");
      let page_type = this.getSrcVariable(this.mofang_url, "page_type");
      let { page_config } = await get("/index/mofang", {
        params: {
          store_id,
          id,
          page_type,
        },
      });
      this.moData = page_config;
      // }
    },

    getSrcVariable(src, variable) {
      /**
       * @method getSrcVariable  history值的获取链接参数
       * @param {string} variable  需要查找的链接参数
       * @return {string} 如果值存在则返回该值 || false
       */
      let query = src.substring(src.indexOf("?") + 1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          // sessionStorage[variable] = pair[1]
          return pair[1];
        }
      }
      return false;
    },

    async getSign() {
      //签到
      let data = await post("/lw.Member/signin_v3", {
        data: {
          welfare_id: this.signin_id,
        },
      });

      let lo = data.signin_data.find(
        (item) => this.signin_id == item.welfare_id
      );

      this.thisSigninObj = lo;
      this.signedShow = true;
      this.signined += 1;
      this.signinStatus = true;
      return false;

      let i = [],
        n = 0;
      for (let index = 0; index < this.max_day; index++) {
        i.push(`${n}`);
        if (n >= 6) {
          n = 0;
        } else {
          n += 1;
        }
      }

      this.thisSigninObj = this.dPointGoodsList[
        this.signined < 7 ? 0 : this.signined >= 7 && this.signined < 14 ? 1 : 2
      ][i[this.signined]];
      this.signined += 1;
      this.signinStatus = true;

      if (this.thisSigninObj.type == "chooese") {
        this.getNoneChooeseData();
      } else {
        if (this.thisSigninObj.type == "lottery") {
          let lo = data.signin_data.find(
            (item) => this.signin_id == item.welfare_id
          );
          this.thisChooeseItem = lo.content;
        }
        this.signedShow = true;
      }
    },
    async getNoneChooeseData() {
      let res = await post("/lw.MemberWelfare/getSigninWelfare_v2");
      if (res) {
        this.thisChooeseItem = res.find((item) => item.day == this.signined);
        if (this.thisChooeseItem && this.thisChooeseItem?.status == 0) {
          let i = [],
            n = 0;
          for (let index = 0; index < this.max_day; index++) {
            i.push(`${n}`);
            if (n >= 6) {
              n = 0;
            } else {
              n += 1;
            }
          }

          let r = this.dPointGoodsList[
            this.signined - 1 < 7
              ? 0
              : this.signined - 1 >= 7 && this.signined - 1 < 14
              ? 1
              : 2
          ][i[this.signined - 1]];
          this.thisSigninObj = {
            type: r.type,
            list: r.choose,
          };

          if (!this.thisChooeseItem.choosed.type) {
            this.signedShow = true;
          }
          // if (r?.type == "choose" && typeof t.choosed == "array") {
          //
          // }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

#points {
  position: relative;
  background: url("~@image/points/bg2.png") no-repeat;
  background-size: 100% rem(434);
  padding-top: rem(116);
  position: relative;
}
.rule,
.reload {
  background: rgba(255, 255, 255, 0.2);
  border: rem(2) solid rgba(255, 255, 255, 0.7);
  box-shadow: 0px rem(4) rem(4) rgba(0, 0, 0, 0.2);
  border-radius: rem(22) 0px 0px rem(22);
  height: rem(44);
  color: #ffffff;
  line-height: rem(44);
  text-align: right;
  right: 0;
  position: absolute;
  padding: 0 rem(4) 0 rem(14);
}
.rule {
  top: rem(73);
}
.reload {
  top: rem(148);
}

.p_info {
  display: flex;
  background: none;
  align-items: center;
  box-sizing: border-box;
  padding: 0 rem(30);

  .p_sub {
    margin-left: rem(32);
    flex: 1;
    font-size: rem(42);
    > span {
      display: block;
      font-weight: bold;
      color: #ffffff;
      > i {
        color: #fed984;
      }
    }
  }

  .p_i_incon {
    width: rem(160);
    height: rem(74);
    border: 1px solid rgba(255, 255, 255, 0.4);
    box-sizing: border-box;
    border-radius: rem(16);
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      width: rem(50);
      height: rem(50);
    }
    > span {
      color: #ffffff;
      font-size: rem(30);
      font-weight: bold;
      margin-left: rem(16);
    }
  }
}

.p_sign {
  width: rem(690);
  // height: rem(430);
  border-radius: rem(16);
  margin: rem(56) auto 0;
  padding: rem(30) rem(24);
  box-sizing: border-box;
  background: #fff;
  .title {
    display: flex;
    align-items: center;
    margin-bottom: rem(30);
    h1 {
      font-weight: bold;
      font-size: rem(28);
      flex: 1;
      color: #222222;
    }
    div {
      color: #ff4848;
      font-size: rem(24);
      display: flex;
      align-items: center;
      i {
        margin: 0 rem(6);
        display: inline-block;
        width: rem(32);
        height: rem(32);
        background: #ff4848;
        border-radius: rem(4);
        text-align: center;
        line-height: rem(32);
        color: #fff;
        font-size: rem(20);
      }
    }
  }
}

.sign-btn {
  width: rem(618);
  height: rem(98);
  background: linear-gradient(180deg, #ff6666 0%, #ff4848 105.88%);
  border-radius: rem(98/2);
  color: #ffffff;
  text-align: center;
  font-size: rem(32);
  font-weight: bold;
  line-height: rem(98);
  margin-top: rem(40);
}

.sign-btn-act {
  background: linear-gradient(180deg, #ff6666 0%, #ff4848 105.88%);
  opacity: 0.3;
}

.get_kefu {
  width: rem(130);
  color: #666666;
  font-size: rem(28);
  text-align: center;
  background: url("~@image/points/right_more.png") no-repeat center right;
  background-size: rem(11) rem(22);
  padding-right: rem(8);
  margin: rem(40) auto rem(20);
}

.no-data {
  font-size: rem(22);
  text-align: center;
  color: #c1c1c1;
  padding: rem(40) 0 rem(20);
}
</style>
